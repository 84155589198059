@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import 'fonts.scss';

body.body--light,
body.body--dark {
  font-family: 'Open Sans', sans-serif;
  font-display: swap;

  .desktop-header {
    position: relative !important;
    background-color: #fbfafa !important;
  }

  .icon-vk {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjQ3My41aDcuMDU0YzYuNDQgMCA3Ljk3MyAxLjUzMyA3Ljk3MyA3Ljk3M3Y3LjA1NGMwIDYuNDQtMS41MzMgNy45NzMtNy45NzMgNy45NzNIOC40NzNDMi4wMzMgMjMuNS41IDIxLjk2Ny41IDE1LjUyN1Y4LjQ3M0MuNSAyLjAzMyAyLjAzMy41IDguNDczLjV6IiBmaWxsPSIjNTE4MUI4Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOS4zMzUgOC40MjRjLjEwNy0uMzU2IDAtLjYxNy0uNTA3LS42MTdoLTEuNjc3Yy0uNDI2IDAtLjYyMy4yMjYtLjczLjQ3NSAwIDAtLjg1MiAyLjA3OS0yLjA2IDMuNDI5LS4zOTEuMzktLjU3LjUxNS0uNzgyLjUxNS0uMTA3IDAtLjI2MS0uMTI0LS4yNjEtLjQ4VjguNDI0YzAtLjQyNy0uMTI0LS42MTctLjQ4LS42MTdoLTIuNjM1Yy0uMjY2IDAtLjQyNi4xOTgtLjQyNi4zODYgMCAuNDA0LjYwNC40OTcuNjY2IDEuNjM0djIuNDdjMCAuNTQyLS4wOTguNjQtLjMxMS42NC0uNTY5IDAtMS45NTItMi4wODgtMi43NzItNC40NzgtLjE2LS40NjQtLjMyMi0uNjUyLS43NS0uNjUySDQuOTMyYy0uNDc5IDAtLjU3NS4yMjYtLjU3NS40NzUgMCAuNDQ0LjU2OSAyLjY0NyAyLjY0OCA1LjU2IDEuMzg2IDEuOTkgMy4zMzggMy4wNyA1LjExNSAzLjA3IDEuMDY2IDAgMS4xOTgtLjI0IDEuMTk4LS42NTN2LTEuNTA0YzAtLjQ3OS4xLS41NzUuNDM4LS41NzUuMjUgMCAuNjc2LjEyNSAxLjY3IDEuMDg0IDEuMTM4IDEuMTM3IDEuMzI1IDEuNjQ4IDEuOTY1IDEuNjQ4aDEuNjc3Yy40NzkgMCAuNzE5LS4yNC41OC0uNzEzLS4xNS0uNDcxLS42OTQtMS4xNTUtMS40MTQtMS45NjUtLjM5MS0uNDYyLS45NzctLjk2LTEuMTU1LTEuMjA4LS4yNDktLjMyLS4xNzgtLjQ2MiAwLS43NDcgMCAwIDIuMDQzLTIuODc4IDIuMjU2LTMuODU1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
  }

  .icon-fb {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI0IDEyYzAtNi42MjctNS4zNzMtMTItMTItMTJTMCA1LjM3MyAwIDEyYzAgNS45OSA0LjM4OCAxMC45NTQgMTAuMTI1IDExLjg1NFYxNS40N0g3LjA3OFYxMmgzLjA0N1Y5LjM1NmMwLTMuMDA3IDEuNzkyLTQuNjY4IDQuNTMzLTQuNjY4IDEuMzEyIDAgMi42ODYuMjM0IDIuNjg2LjIzNHYyLjk1M0gxNS44M2MtMS40OTEgMC0xLjk1Ni45MjUtMS45NTYgMS44NzVWMTJoMy4zMjhsLS41MzIgMy40NjloLTIuNzk2djguMzg1QzE5LjYxMiAyMi45NTQgMjQgMTcuOTkgMjQgMTJ6IiBmaWxsPSIjMTg3N0YyIi8+PHBhdGggZD0iTTE2LjY3MSAxNS40NjlMMTcuMjAzIDEyaC0zLjMyOFY5Ljc1YzAtLjk0OS40NjUtMS44NzUgMS45NTYtMS44NzVoMS41MTNWNC45MjJzLTEuMzc0LS4yMzQtMi42ODYtLjIzNGMtMi43NDEgMC00LjUzMyAxLjY2LTQuNTMzIDQuNjY4VjEySDcuMDc4djMuNDY5aDMuMDQ3djguMzg1YTEyLjEzIDEyLjEzIDAgMDAzLjc1IDBWMTUuNDdoMi43OTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+');
  }

  .icon-gl {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuMzE5IDE0LjUwNGwtLjgzNSAzLjExOC0zLjA1NC4wNjVBMTEuOTQ2IDExLjk0NiAwIDAxMCAxMmMwLTEuOTkuNDg0LTMuODY2IDEuMzQyLTUuNTE5bDIuNzE5LjQ5OSAxLjE5IDIuNzAyQTcuMTMzIDcuMTMzIDAgMDA0Ljg2OCAxMmMwIC44ODEuMTYgMS43MjUuNDUyIDIuNTA0eiIgZmlsbD0iI0ZCQkIwMCIvPjxwYXRoIGQ9Ik0yMy43OSA5Ljc1OGExMi4wMjggMTIuMDI4IDAgMDEtLjA1MyA0Ljc0NyAxMS45OTggMTEuOTk4IDAgMDEtNC4yMjUgNi44NTNsLTMuNDI0LS4xNzUtLjQ4NS0zLjAyNWE3LjE1MiA3LjE1MiAwIDAwMy4wNzctMy42NTJoLTYuNDE2VjkuNzU4SDIzLjc5eiIgZmlsbD0iIzUxOEVGOCIvPjxwYXRoIGQ9Ik0xOS41MTIgMjEuMzU3QTExLjk1IDExLjk1IDAgMDExMiAyNGMtNC41NyAwLTguNTQzLTIuNTU0LTEwLjU3LTYuMzEzbDMuODg5LTMuMTg0YTcuMTM1IDcuMTM1IDAgMDAxMC4yODQgMy42NTRsMy45MDkgMy4yeiIgZmlsbD0iIzI4QjQ0NiIvPjxwYXRoIGQ9Ik0xOS42NiAyLjc2M2wtMy44ODggMy4xODJhNy4xMzcgNy4xMzcgMCAwMC0xMC41MiAzLjczNmwtMy45MS0zLjJBMTEuOTk4IDExLjk5OCAwIDAxMTIgMGExMS45NSAxMS45NSAwIDAxNy42NiAyLjc2M3oiIGZpbGw9IiNGMTQzMzYiLz48L3N2Zz4=');
  }

  .icon-ok {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjAwMSA0LjMyOGEyLjM1MyAyLjM1MyAwIDAxMi4zNSAyLjM1IDIuMzUzIDIuMzUzIDAgMDEtMi4zNSAyLjM1IDIuMzUzIDIuMzUzIDAgMDEtMi4zNS0yLjM1QTIuMzUzIDIuMzUzIDAgMDExMiA0LjMyOHptMCA4LjAyNmE1LjY4MyA1LjY4MyAwIDAwNS42NzgtNS42NzZBNS42ODQgNS42ODQgMCAwMDEyLjAwMSAxYTUuNjg0IDUuNjg0IDAgMDAtNS42NzggNS42NzggNS42ODMgNS42ODMgMCAwMDUuNjc4IDUuNjc2em0yLjI5NyA0LjYzMWExMC42MjkgMTAuNjI5IDAgMDAzLjI5Ny0xLjM2NSAxLjY2MyAxLjY2MyAwIDAwLTEuNzcyLTIuODE2IDcuMjEgNy4yMSAwIDAxLTcuNjQ0IDAgMS42NjIgMS42NjIgMCAwMC0yLjUwOCAxLjc3OGMuMDk4LjQzLjM2My44MDMuNzM2IDEuMDM4YTEwLjY0IDEwLjY0IDAgMDAzLjI5NiAxLjM2NUw2LjUzIDIwLjE2YTEuNjY0IDEuNjY0IDAgMDAyLjM1MyAyLjM1MmwzLjExOS0zLjExOSAzLjEyIDMuMTJhMS42NiAxLjY2IDAgMDAyLjcxMi0uNTQgMS42NjIgMS42NjIgMCAwMC0uMzYxLTEuODEybC0zLjE3NC0zLjE3NnoiIGZpbGw9IiNFQjcyMkUiLz48L3N2Zz4=');
  }

  .icon-ml {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiByeD0iNCIgZmlsbD0iIzAwNUZGOSIvPjxwYXRoIGQ9Ik0xNC43MDcgMTJBMi43MSAyLjcxIDAgMDExMiAxNC43MDcgMi43MSAyLjcxIDAgMDE5LjI5MyAxMiAyLjcxIDIuNzEgMCAwMTEyIDkuMjkzIDIuNzEgMi43MSAwIDAxMTQuNzA3IDEyek0xMiAzYy00Ljk2MyAwLTkgNC4wMzctOSA5czQuMDM3IDkgOSA5YTguOTQ4IDguOTQ4IDAgMDA1LjA3LTEuNTY1bC4wMjUtLjAxOC0xLjIxMi0xLjQxLS4wMi4wMTNBNy4xMiA3LjEyIDAgMDExMiAxOS4xNTVjLTMuOTQ1IDAtNy4xNTUtMy4yMS03LjE1NS03LjE1NSAwLTMuOTQ1IDMuMjEtNy4xNTUgNy4xNTUtNy4xNTUgMy45NDUgMCA3LjE1NSAzLjIxIDcuMTU1IDcuMTU1IDAgLjUxMS0uMDU3IDEuMDI5LS4xNjggMS41MzgtLjIyNy45MjktLjg3NyAxLjIxMy0xLjM2NSAxLjE3NS0uNDkxLS4wNC0xLjA2Ni0uMzktMS4wNy0xLjI0NVYxMkE0LjU1NyA0LjU1NyAwIDAwMTIgNy40NDggNC41NTcgNC41NTcgMCAwMDcuNDQ4IDEyIDQuNTU3IDQuNTU3IDAgMDAxMiAxNi41NTJjMS4yMiAwIDIuMzYzLS40NzYgMy4yMjUtMS4zNDNhMi45MjUgMi45MjUgMCAwMDIuNDkgMS4zNTNjLjY1NiAwIDEuMzA0LS4yMTkgMS44MjgtLjYxNi41MzktLjQxLjk0Mi0xLjAwMiAxLjE2NS0xLjcxMy4wMzUtLjExNS4xLS4zNzguMS0uMzhsLjAwMi0uMDFjLjEzMi0uNTcuMTktMS4xNC4xOS0xLjg0MyAwLTQuOTYzLTQuMDM3LTktOS05eiIgZmlsbD0iI0ZGOUUwMCIvPjwvc3ZnPg==');
  }

  .icon-tw {
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuODIzIDIxYzguODI0IDAgMTMuNjUtNy4zMSAxMy42NS0xMy42NSAwLS4yMDggMC0uNDE0LS4wMTQtLjYyYTkuNzYxIDkuNzYxIDAgMDAyLjM5NC0yLjQ4NCA5LjU3NiA5LjU3NiAwIDAxLTIuNzU2Ljc1NSA0LjgxNCA0LjgxNCAwIDAwMi4xMS0yLjY1MyA5LjYxNCA5LjYxNCAwIDAxLTMuMDQ3IDEuMTY0IDQuODAyIDQuODAyIDAgMDAtOC4xNzYgNC4zNzYgMTMuNjIgMTMuNjIgMCAwMS05Ljg4Ny01LjAxM0E0LjgwMSA0LjgwMSAwIDAwMy41ODIgOS4yOGE0Ljc2MiA0Ljc2MiAwIDAxLTIuMTc3LS42di4wNmE0LjggNC44IDAgMDAzLjg0OSA0LjcwNCA0Ljc5IDQuNzkgMCAwMS0yLjE2Ny4wODIgNC44MDMgNC44MDMgMCAwMDQuNDgzIDMuMzMyIDkuNjI2IDkuNjI2IDAgMDEtNy4xIDEuOTg4IDEzLjU4MyAxMy41ODMgMCAwMDcuMzUzIDIuMTUxIiBmaWxsPSIjMURBMUYyIi8+PC9zdmc+');
  }

  .q-dialog .q-dialog__inner {
    padding: 0;
  }

  .roboto {
    font-family: 'Roboto', sans-serif;
    font-display: swap;
  }

  .font-9 {
    font-size: 9px;
  }

  .font-10 {
    font-size: 10px;
  }

  .font-11 {
    font-size: 11px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-13 {
    font-size: 13px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-15 {
    font-size: 15px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }

  .font-20 {
    font-size: 20px;
  }

  .font-22 {
    font-size: 22px;
  }

  .font-24 {
    font-size: 24px;
  }

  .font-26 {
    font-size: 26px;
  }

  .font-28 {
    font-size: 26px;
  }

  .font-30 {
    font-size: 30px;
  }

  .font-32 {
    font-size: 32px;
  }

  .font-34 {
    font-size: 34px;
  }

  .font-36 {
    font-size: 36px;
  }

  .font-40 {
    font-size: 40px;
  }

  .font-42 {
    font-size: 42px;
  }

  .font-48 {
    font-size: 48px;
  }

  .font-54 {
    font-size: 54px;
  }

  .font-60 {
    font-size: 60px;
  }

  .white-space-nowrap {
    white-space: nowrap;
  }

  .q-card.popup-form {
    background: $app-color-background;
    width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: initial;

    .q-card__section,
    .q-card__actions {
      max-width: 480px;

      a,
      a.q-btn {
        color: $grey-8;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        transition: color 0.2s ease-in-out;
        padding: 0;

        &:hover {
          color: $grey-10;
        }
      }

      a.q-btn {
        small {
          font-size: 12px;
          margin-right: 22px;
        }

        .q-btn__wrapper {
          padding: 0;
        }

        .q-focus-helper {
          display: none;
        }
      }
    }

    .q-card__actions .q-btn:not(.flat) {
      min-width: 290px;
    }

    @media (max-width: $breakpoint-xs-max) {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;

      .q-card__section,
      .q-card__actions {
        max-width: 300px;
      }

      .q-card__actions .q-btn:not(.flat) {
        min-width: 270px;
      }
    }

    @media (max-width: $breakpoint-sm-max) and (orientation: landscape) {
      min-width: 100%;
      min-height: 100%;
      border-radius: 0;
    }
  }

  .dashed-link {
    cursor: pointer;
    border-bottom: 1px dashed $positive;
    color: $positive;
    text-decoration: none;
  }

  .dashed-link-grey-12 {
    cursor: pointer;
    border-bottom: 1px dashed $grey-12;
    color: $positive;
    text-decoration: none;
  }

  .link {
    cursor: pointer;
    color: $positive;
    text-decoration: none;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .menu {
    left: 50% !important;
    transform: translateX(-50%);
    max-height: 800px !important;
  }

  .menu-fix {
    max-height: 80vh !important;
    top: 80px !important;
    transition: all 0.4s;

    &-adult {
      top: 188px !important;
      transition: all 0.4s;
    }
  }

  .q-page {
    min-height: 210px !important;
  }

  .dadata-input {
    border-radius: $button-border-radius !important;

    &:focus {
      border: 1px solid $positive !important;
      outline: none !important;
    }
  }

  .suggestions-wrapper {
    width: 100% !important;
    z-index: 2;

    .suggestions-suggestions {
      max-width: 100% !important;
      border-radius: $button-border-radius !important;
      left: 0 !important;
      top: 10px !important;
      border: none !important;
      padding: 10px 0 10px 18px !important;
      box-shadow: $menu-box-shadow !important;

      .suggestions-suggestion {
        padding-left: 0px !important;
        border: none !important;
      }
    }
  }

  .wrapper {
    margin: auto;
    min-width: initial !important;
  }

  .q-menu {
    border-radius: $mobile-border-radius;
    max-height: 100vh;
  }

  @media (min-width: 800px) {
    .wrapper {
      width: 780px !important;
    }
  }

  @media (min-width: 960px) {
    .wrapper {
      width: 926px !important;
    }
  }

  @media (min-width: 1024px) {
    .wrapper {
      width: 926px !important;
    }
  }

  @media (min-width: 1120px) {
    .wrapper {
      width: 926px !important;
    }
  }

  @media (min-width: 1280px) {
    .wrapper {
      width: 1240px !important;
    }
  }

  @media (min-width: 1440px) {
    .wrapper {
      width: 1310px !important;
    }
  }
}

.default-mobile-field {
  .q-field {
    &__control {
      border-radius: $mobile-border-radius;

      &:after {
        border-width: 2px;
      }
    }

    &__native {
      &::placeholder {
        color: $gray !important;
      }
    }
  }
}

.q-layout-grey {
  background-color: $grey-3;
}

.absolute-top-center {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-left-center {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.dotted-positive {
  border-bottom: 1px dotted $positive;
}

.text-weight-sm {
  font-weight: 400;
}

.text-grey-19 {
  color: $grey-19
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.border-grey-5 {
  border: 1px solid $grey-5;
}

.border-grey-8 {
  border: 1px solid $grey-8;
}

.border-grey-9 {
  border: 1px solid $grey-9;
}

.border-grey-18 {
  border: 1px solid $grey-18;
}

.border-positive {
  border: 1px solid $positive;
}

.border-negative {
  border: 1px solid $negative;
}

.outline-negative {
  outline: 1px solid $negative;
}

.border-negative-thin {
  border: 1px solid $negative;
}

.disable_link {
  pointer-events: none;
  cursor: default;
  opacity: 0.8;
}

.hover-color-positive {
  &:hover {
    color: $positive !important;
    transition: $main-transition;
  }
}

.hover-color-blue-14 {
  &:hover {
    color: $blue-14 !important;
  }
}

.filters {
  margin-bottom: 35px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-bottom: 0;
  }

  &__checkbox {
    margin-left: -7px;

    & .q-checkbox__bg {
      border-radius: 4px;
    }

    &[aria-checked='false'] .q-checkbox__bg {
      border: 1px solid $grey-9;
    }

    & .q-checkbox__label {
      font-size: 16px;
      margin-left: 6px;
      color: $app-text-primaty;

      @media screen and (max-width: $breakpoint-mobile) {
        font-size: 14px;
      }
    }

    &-f22 {
      & .q-checkbox__label {
        font-size: 20px;
        color: $primary;

        @media (max-width: 1280px) {
          font-size: 16px;
        }
        @media (max-width: 960px) {
          font-size: 12px;
        }
        @media screen and (max-width: $breakpoint-mobile) {
          font-size: 14px;
        }
      }
    }

    &-strong {
      .q-checkbox__label {
        font-weight: 600;
        margin-left: 4px;
      }
    }
  }

  &__shipping {
    font-size: 16px;
    color: $app-text-primaty;
    margin-left: 40px;
  }

  &__clear {
    font-size: 16px;
    color: $positive;
    border-bottom: 1px dashed $positive;
  }
}

.transition-all {
  transition: $main-transition;
}

.q-pt-3 {
  padding-top: 3px;
}

.bordered {
  border: $app-border;
  border-radius: $button-border-radius;
}

.bordered-hover-positive:hover {
  border: 1px solid $positive;
}

.bordered-hover-positive-2x:hover {
  border: 2px solid $positive;
}

.bg-hover-bg-grey-3 {
  &:hover {
    background: $grey-3;
  }
}

.bordered-positive {
  border: 1px solid $positive;
}

.bordered-2px-positive {
  border: 2px solid $positive;
}

.border-x-none {
  border-left: 0;
  border-right: 0;
}

.b-radius-0 {
  border-radius: 0 !important;
}

.b-radius-4 {
  border-radius: 4px !important;
}

.b-radius-6 {
  border-radius: 6px !important;
}

.b-radius-8 {
  border-radius: 8px !important;
}

.b-radius-10 {
  border-radius: 10px !important;
}

.b-radius-12 {
  border-radius: 12px !important;
}

.b-radius-16 {
  border-radius: 16px !important;
}

.b-radius-20 {
  border-radius: 20px !important;
}

.b-radius-24 {
  border-radius: 20px !important;
}

.cursor-default {
  cursor: default;
}

.line-height-12 {
  line-height: 12px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-36 {
  line-height: 36px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-42 {
  line-height: 42px;
}

.line-height-44 {
  line-height: 44px;
}

.line-height-46 {
  line-height: 46px;
}

.line-height-58 {
  line-height: 58px;
}

.line-height-66 {
  line-height: 66px;
}

.q-spinner-mat {
  animation: q-spin 0.35s linear infinite;
}

.text {
  &-xs {
    @media screen and (max-width: $breakpoint-mobile) {
      &-center {
        text-align: center;
      }

      &-left {
        text-align: left;
      }

      &-right {
        text-align: right;
      }
    }
  }
}

.back-icon {
  &::after {
    content: '';
    position: absolute;
    width: 250%;
    height: 250%;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-20 {
  width: 20%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-60 {
  width: 60%;
}

.width-80 {
  width: 80%;
}

.full-height-vw {
  width: 100vw !important;
}

.full-height-vh {
  height: 100vh !important;
}

.outline-none {
  outline: none !important;
}

.filter-input {
  .q-field__control {
    border-radius: 10px;

    &:hover:before {
      border-color: $accent !important;
    }
  }

  .q-field__bottom {
    padding: 4px 0 0;
  }

  .q-field__native {
    line-height: 16px;
    padding: 5px 10px 5px 3px;
    color: $app-text-primaty;

    font: {
      size: 14px;
      weight: 400;
    }

    &::placeholder {
      color: $gray !important;
    }
  }

  .q-field__control:before {
    border: 1px solid $accent !important;
  }

  .q-field__append {
    height: 100% !important;
    color: $medium-gray;

    .q-icon {
      font-size: 16px !important;
    }
  }

  &__apartment,
  &__entrance {
    .q-field__control {
      @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }

  &__floor,
  &__intercom {
    .q-field__control {
      @media screen and (max-width: $breakpoint-mobile) {
        border-radius: 0 8px 8px 0 !important;
      }
    }
  }
}

.app-primary {
  color: #242424;
}

.basket-modal {
  color: #242424;
  padding-bottom: 70px;
  background-color: #fbfafa;

  &__button-block {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    z-index: 9;
  }
}

.page-top-area {
  height: constant(safe-area-inset-top);
  height: env(safe-area-inset-top);
  z-index: 9;
  background-color: #fbfafa;
}

.info-mobile {
  background-color: #fbfafa;
  min-height: 100vh;
}

// display block
@media screen and (min-width: 600px) {
  .d-sm-block {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) {
  .d-md-block {
    display: block !important;
  }
}

@media screen and (min-width: 1440px) {
  .d-lg-block {
    display: block !important;
  }
}

@media screen and (min-width: 1920px) {
  .d-xl-block {
    display: block !important;
  }
}

// display flex
@media screen and (min-width: 600px) {
  .d-sm-flex {
    display: flex !important;
  }
}

@media screen and (min-width: 1024px) {
  .d-md-flex {
    display: flex !important;
  }
}

@media screen and (min-width: 1440px) {
  .d-lg-flex {
    display: flex !important;
  }
}

@media screen and (min-width: 1920px) {
  .d-xl-flex {
    display: flex !important;
  }
}

// display none
@media screen and (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .d-md-none {
    display: none !important;
  }
}

@media screen and (min-width: 1440px) {
  .d-lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 1920px) {
  .d-xl-none {
    display: none !important;
  }
}

h1 {
  line-height: 1.5rem;

  @media screen and (max-width: 425px) {
    line-height: 1rem;
    margin: 0;
  }
}

.empty-img {
  width: 320px;

  @media screen and (max-width: $breakpoint-mobile) {
    width: calc(100% - 110px);
  }
}

.center-img {
  width: 600px;

  @media screen and (max-width: $breakpoint-mobile) {
    width: calc(100% - 110px);
  }
}

h2,
h3 {
  line-height: 27px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 16px;

  @media screen and (max-width: $breakpoint-mobile) {
    font-size: 16px;
    padding-bottom: 8px;
    line-height: 18px;
  }
}

.text-subtitle2 {
  margin-top: 12px;
}

.max-width-86 {
  max-width: 86%;
}

.ios-padding-bottom {
  padding-bottom: calc(8px + constant(safe-area-inset-bottom));
  padding-bottom: calc(8px + env(safe-area-inset-bottom));
}

.z-100 {
  z-index: 100;
}

.q-select__dialog {
  @media screen and (max-width: $breakpoint-mobile) {
    position: absolute;
    top: 50vh;
    border-radius: 10px !important;
  }
}

.franch-shadow {
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
}

.text-end {
  text-align: end;
}

.border-positive-strong {
  border-bottom: 2px solid $positive;
}

.mb-32 {
  margin-bottom: 32px;
}

.no-padding {
  padding: 0 !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.letter-spacing-min {
  letter-spacing: -1px !important;
}

.decoration-no {
  text-decoration: none;
}

// .yaPersonalButton {
//     margin: 0 16px !important;
//     width: 368px !important;
//}
.text-line-through {
  text-decoration: line-through;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.tid-4PNRE-button-primary {
  width: 100%;
  border-radius: 30px !important;
}

.bg-blue-15 {
  background: $blue-15;
}

.bg-white-b {
  background: $white-b;
}

.prevew-mobile:first-child {
  margin-left: 16px;
}

a {
  text-decoration: none;
}

.custom-html {
  & p,
  & h2 {
    background-color: transparent !important;
  }
}

.customInputClassMobile {
  flex: auto;
  display: block;
  width: 100% !important;
}

.iris-banner-wrap {
  & .desktop-full.not-empty {
    height: 130px !important;
  }

  & .desktop-md.not-empty {
    height: 160px !important;
  }

  & .desktop-long.not-empty {
    height: 430px !important;
  }

  & .mobile-full.not-empty {
    height: 100px !important;
  }


}

